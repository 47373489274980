import React from 'react';
import { ApplicationState } from '../../types';
import { ControllerActions } from '../../actions/actions';

export type ApplicationContext = {
  actions: ControllerActions;
} & ApplicationState;

const ApplicationComponentContext = React.createContext<ApplicationContext>(
  {} as ApplicationContext,
);
export const ApplicationContextProvider = ApplicationComponentContext.Provider;

export const useApplicationContext = () =>
  React.useContext(ApplicationComponentContext);
